import styled from "styled-components";

function Turquoise({}) {



    return (
        <>
            <PageStyled>
                <iframe
                    src = "https://drive.google.com/file/d/1q41-WE4A5Nf8VnRASky7h_OVy8moeDzR/preview"
                    width = "100%"
                    height = "100%"
                    allow = "autoplay"
                />
            </PageStyled>
        </>
    )
}

const PageStyled = styled.div`
    position: absolute;
    inset: 0;
    width: 100%;
`

export default Turquoise;